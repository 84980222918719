.StlApp {
    font-family: sans-serif;
    text-align: center;
    height: 100vh;
    width: 100vw;
}

body {
  padding: 0;
  margin: 0;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

body {
    text-align: center;
    padding: 0;
    margin: 0;
    color: black;
    background: #424250;
}
.footer-text{

  background-color: #fff;
  margin-top: 20px;
  position: absolute;
  width: 100%;
  bottom: 10px;
  left: 50%;
  transform: translate(-50%);
}
