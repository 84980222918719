/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.App {

    text-align: center;
    height: 100vh;
    width: 100vw;
}

body {
    padding: 0;
    margin: 0;
    color: black;
    background: #424250;
}

.generator-button {
  position: fixed;
  bottom: 2px;
  left: 50%;
  width: 50px;
  height: 48px;
  border-radius: 50%;
  /* background: #117be6; */
  border: 0;
  z-index: 1;
}
.generator-button2 {
  position: fixed;
  right: 7px;
}
.generator-hover {
  position: fixed;
  bottom: 5px;
  left: 50%;
  transform: translateX(-50%);
}
.car-number-content {
  position: fixed;
  width: 100%;
  top: 12px;
  left: 50%;
  transform: translateX(-50%);
}
.car-number-text{
  width: 60%;
  text-align: center;
}
.center-button{
  left: 50%;
  transform: translateX(-50%);
}
.home-button{
  position: fixed;
  /* top: 5%; */
  top: 80px;
  left: 7px;
}
.save-button{
  position: fixed;
  left: 7px;
}
.print-button{
  position: fixed;
  width: 200px;
  bottom: 20px;
  /* right: 7px; */
  left: 50%;
  transform: translateX(-50%);
}
.printer-refresh-button{
  width: '20%';
}